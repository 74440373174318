import Vue  		from 'vue'
import Vuex 		from 'vuex'

import customers    from './modules/customers.js';
import products   	from './modules/products.js';
import sales       	from './modules/sales.js';
import store		from './modules/store.js';

Vue.use(Vuex);
const createStore = () => {
	return new Vuex.Store({
		state: {
			company_data        : null,
			app_version         : null,
			notification    	: null,
		},
		getters: {
			getterCompanyData       : state => state.company_data,
			getterAppVersion        : state => state.app_version,
			getterNotification		: state => state.notification
		},
		mutations: {
			setCompanyData(state, payload) {
				state.company_data = payload
			},
			setAppVersion(state, payload) {
				state.app_version = payload
			},
			setNotification(state, payload) {
				state.notification = payload;
			},
		},
		actions: {
			addNotification({ commit }, payload) {
				
				commit('setNotification', payload);
			},
			async nuxtServerInit({ commit }, { $axios }) {
				await $axios.$get(`/general-settings/find`).then( (axios_response) => { 
					const { data } = axios_response;
					commit('setCompanyData', data.company_data);
					this.$cookies.set('general-settings', JSON.stringify( data.company_data ), { path: '/', maxAge: 60 * 60 * 24 * 7 });
					
				}).catch((axios_error) => {
					
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
				});
			},
			async updateCompanyData({commit}, payload) {
				
				commit('setCompanyData', payload);
			},
			async getGeneralSettings({commit}, payload) {
				
				await this.$hefesto.$get(`/general-settings/find`).then( (axios_response) => { 
					const { data } = axios_response;
					commit('setCompanyData', data.company_data);
					this.$cookies.set('general-settings', JSON.stringify( data.company_data ), { path: '/', maxAge: 60 * 60 * 24 * 7 });
					
				}).catch((axios_error) => {
					
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
				});
			},
		},
		modules: {
			customers,
			products,
			sales,
			store
		}
	});
}
export default createStore
