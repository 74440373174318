export default {
	state: () => ({
		orders					: [],
		order					: undefined,
		preorders				: [],
		listBuyAgain			: [],
		checkoutOrder			: null,
		validCheckout   		: null,
		shippingData			: null,
		businesses				: null,
		draftOrder				: null,
		statusPay				: null,
		statusPayBalance		: null,
		transactions			: [],
		url_download_cart_file	: ""
	}),
	mutations: {
		setOrders(state, payload) {
			state.orders = payload
		},
		setOrder(state, payload) {
			state.order = payload
		},
		setPreorders(state, payload) {
			state.preorders = payload
		},
		setBuyAgainProducts: (state, payload) =>  {
			state.listBuyAgain = payload
		},
		setCheckoutOrder(state, payload) {
			state.checkoutOrder = payload
		},
		setValidCheckout(state, payload) {
			state.validCheckout = payload;
		},
		setStatusPay(state, payload) {
			state.statusPay = payload
		},
		setStatusPayBalance(state, payload) {
			state.statusPayBalance = payload
		},
		setBusinesses(state, payload) {
			state.businesses = payload
		},
		setTransactions(state, payload) {
			state.transactions = payload
		},
		setDownloadTransactionsFile(state, payload) {
			state.url_download_cart_file = payload;
		},
		setShippingData(state, payload) {
			state.shippingData = payload
		},
	},
	getters: {
		getterOrder						: state => state.order,
		getterOrders					: state => state.orders,
		getterPreorders					: state => state.preorders,
		getterListBuyAgain				: state => state.listBuyAgain,
		getterCheckoutOrder				: state => state.checkoutOrder,
		getterValidCheckout				: state => state.validCheckout,
		getterStatusPay					: state => state.statusPay,
		getterStatusPayBalance			: state => state.statusPayBalance,
		getterBusinesses				: state => state.businesses,
		getterTransactions				: state => state.transactions,
		getterShippingData				: state => state.shippingData,
		getterDownloadTransactionsFile	: state => state.url_download_cart_file,
	},
	actions: {
		// =================================================================
		//  ORDER ACTIONS
		// =================================================================
		async getOrder({commit}, payload) {
			
			await this.$hefesto.$get(`/orders/find/${ payload.name }`).then( (axios_response) => {
				
				commit('setOrder', axios_response.data);
			}).catch( (axios_error) => {
				
				commit('setOrder', null);
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async getOrders({commit}, payload) {
			
			let { page, search, min_date, max_date } = payload;
			
			await this.$hefesto.$get(`/orders/list?page=${ page }&min_date=${ min_date }&max_date=${ max_date }&search=${ encodeURI( search ) }`).then( (axios_response) => {
				
				const { data } = axios_response;
				commit('setOrders', data);
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async createOrder({commit}, payload) {
			
			await this.$hefesto.$post(`/orders/valid-checkout`, { checkout: JSON.stringify( payload.checkout ) }).then( async (response_valid) => {
				
				await this.$agent.$post(`/draft-order/create`, payload.draft_order).then( async (response_draft) => {
					
					payload.draft_order.shopify = JSON.parse( payload.draft_order.shopify );
					payload.draft_order.shopify.note_attributes.push({ name: "draft-order-db", value: response_draft.body._id });
					payload.draft_order.shopify = JSON.stringify( payload.draft_order.shopify );
					
					await this.$hefesto.$post('/orders/create', payload.draft_order).then( async (axios_response) => {
						
						const  { data } = axios_response;
						commit( 'setCheckoutOrder', { valid: true, data: data } );
					}).catch( (axios_error) => {
						
						const  { data, title, message } = axios_error.response.data;
						
						if( data.status != "order-not-created" ){
							
							commit( 'setCheckoutOrder', { valid: true, data: data } );
						}
						else{
							
							commit( 'setCheckoutOrder', { valid: false, data: { title: title, message: message } } );
							commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data, isCheckout: true });
						}
					});
				}).catch( (axios_error) => {
					
					commit( 'setCheckoutOrder', { valid: false, data: { title: "Error: Create Order", message: axios_error.response.data.message } } );
					axios_error.response.data.title = "Error: Create Order";
					axios_error.response.data.app_version = this.$cookies.get('app-version');
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data, isCheckout: true });
				});
			}).catch( (axios_error) => {
				
				commit('setValidCheckout', false);
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data, isCheckout: true });
			})
		},
		async payOrder({commit}, payload) {
			
			await this.$hefesto.$post(`/orders/valid-checkout`, { checkout: JSON.stringify( payload.checkout ) }).then( async (valid_response) => {
				
				await this.$agent.$post(`/draft-order/create`, payload.draft_order).then( async (axios_response) => {
					
					const { body } = axios_response;
					
					payload.draft_order.shopify = JSON.parse( payload.draft_order.shopify );
					payload.draft_order.shopify.note_attributes.push({ name: "draft-order-db", value: body._id });
					payload.draft_order.shopify = JSON.stringify( payload.draft_order.shopify );
					payload.payment.db_draft_id = body._id;
					
					await this.$hefesto.$post('/orders/create/draft/shopify', { shopify_data: payload.draft_order.shopify }).then( async (axios_draft_response) => {
						
						const { data } = axios_draft_response;
						payload.payment.draft_id 		= data.id;
						payload.payment.note_attributes = data.note_attributes;
						payload.payment.user_id 		= data.user_id;
						payload.payment.customer_id 	= data.customer_id;
						await this.$billing.$post(`/payment/pay-order/${ payload.payment.customer_id }`, payload.payment).then( (axios_response) => {
							
							if( axios_response.result == "approved"){
								
								commit('setStatusPay', { valid: true, data: axios_response });
							}
							else{
								
								commit('setStatusPay', { valid: false, data: { title: "Error: Pay Order", message: axios_response.message } });
								commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: "Error: Pay Order", message: axios_response.message, app_version: this.$cookies.get('app-version') }, isCheckout: true });
							}
						}).catch( (axios_error) => {
							
							commit('setStatusPay', { valid: false, data: { title: "Error: Pay Order", message: axios_error.response.data.message } });
							commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: "Error: Pay Order", message: axios_error.response.data.message, app_version: this.$cookies.get('app-version') }, isCheckout: true });
						});
					}).catch( (axios_error) => {
						
						commit('setStatusPay', { valid: false, data: { title: axios_error.response.data.title, message: axios_error.response.data.message } });
						commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data, isCheckout: true });
					});
				}).catch( (axios_error) => {
					
					commit('setStatusPay', { valid: false, data: { title: "Error: Create Order", message: axios_error.response.data.message } });
					axios_error.response.data.title = "Error: Create Order";
					axios_error.response.data.app_version = this.$cookies.get('app-version');
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data, isCheckout: true });
				});
			}).catch( (axios_error) => {
				
				commit('setValidCheckout', false);
				if( error.response.status != 403 ){
					
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
				}
			})
		},
		async payBalance({commit}, payload) {
			
			await this.$billing.$post(`/payment/pay-order/${ payload.payment.customer_id }`, payload.payment).then( (axios_response) => {
				
				commit('setStatusPayBalance', axios_response);
				
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: "Error: Pay Balance", message: axios_error.response.data.message, app_version: this.$cookies.get('app-version') } });
			});
		},
		// =================================================================
		//  PREORDERS ACTIONS
		// =================================================================
		async listProductPreorders({commit}, payload) {
			await this.$hefesto.$get(`/preorders/get-products`).then( (axios_response) => {
				
				const { data } = axios_response;
				commit( 'setPreorders', data );
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			})
		},
		// =================================================================
		//  BUYAGAIN ACTIONS
		// =================================================================
		async listBuyAgainProducts({commit}, payload) {
			
			await this.$hefesto.$get(`/buy-again/list?sort=last_purcharse&sort_direction=desc`).then( (axios_response) => {
				
				const { data } = axios_response;
				commit('setBuyAgainProducts', data);
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			})
		},
		// =================================================================
		//  CHECKOUT ACTIONS
		// =================================================================
		async resetShippingData({commit}, payload) {
			
			commit('setShippingData', payload);
		},
		async resetValidCheckout({commit}, payload) {
			
			commit('setValidCheckout', payload);
		},
		async getBusinesses({commit}, payload) {
			
			await this.$hefesto.$get(`/businesses/list`).then( (axios_response) => {
				
				const { data } = axios_response;
				commit( 'setBusinesses', data );
				
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			})
		},
		async calculateShipping({commit}, payload) {
			
			await this.$hefesto.$post(`/orders/shipping-options?product_origin=${ payload.product_origin }`,  payload ).then( (axios_response) => {
				
				const { data } = axios_response;
				commit('setShippingData', data.details);
				commit('setCoupon', data.coupon);
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			})
		},
		async validCheckout({commit}, payload) {
			
			await this.$hefesto.$post(`/orders/valid-checkout`, { checkout: JSON.stringify( payload ) }).then( (axios_response) => {
				
				commit('setValidCheckout', axios_response.data.valid);
			}).catch( (axios_error) => {
				
				commit('setValidCheckout', false);
				if( error.response.status != 403 ){
					
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
				}
			})
		},
		// =================================================================
		//  TRANSACTION ACTIONS
		// =================================================================
		async getTransactions({commit}, payload) {
			
			await this.$hefesto.$get(`/app-billing/transactions?page=${ payload.page }&search=${ encodeURI(payload.search) }&from=${ payload.min_date }&to=${ payload.max_date }`).then( (axios_response) => {
				
				const { data } = axios_response;
				commit( 'setTransactions', data );
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async downloadExportTransactionsFile({commit}, payload) {
			
			await this.$hefesto.$get(`/orders/transactions/download-file?page=${ payload.page }&from=${ payload.min_date }&to=${ payload.max_date }`).then( (axios_response) => {
				
				const { data } = axios_response;
				commit('setDownloadTransactionsFile', "");
				commit('setDownloadTransactionsFile', data.url);
				commit('setNotification', { content: { type: 'request' }, type: 'success', data: axios_response });
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async deleteExportTransactionsFile({commit}, payload) {
			
			await this.$hefesto.$post(`/orders/transactions/delete-file`,{ url_file: payload.url_file }).then( (axios_response) => {
				
				commit('setDownloadTransactionsFile', "");
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			})
		}
	}
}