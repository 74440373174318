import { randomNumber, validUploadFile } 	from '../../constants/config';

export default {
	state: () => ({
		user				: null,
		isLogin				: false,
		change_password		: null,
		lead				: null,
		customer			: null,
		customerLanding		: null,
		profile_image		: null,
		customerLeadAgent	: null,
		level				: null,
		statement			: null,
		customerAgent		: null,
		customerAddresses	: [],
		validCustomer		: null
	}),
	mutations: {
		setUser(state, payload) {
			state.user = payload
		},
		setChangePassword(state, payload) {
			state.change_password = payload
		},
		setLogin(state, payload) {
			state.isLogin = payload
		},
		setLead(state, payload) {
			state.lead = payload
		},
		setCustomer(state, payload) {
			state.customer = payload
		},
		setCustomerLanding(state, payload) {
			state.customerLanding = payload
		},
		setCustomerLeadAgent(state, payload) {
			state.customerLeadAgent = payload
		},
		setProfileImage(state, payload){
			state.profile_image = payload;
		},
		setLevel(state, payload) {
			state.level = payload
		},
		setStatement(state, payload) {
			state.statement = payload
		},
		setCustomerAgent(state, payload){
			state.customerAgent = payload;
		},
		setCustomerAddresses(state, payload) {
			state.customerAddresses = payload
		},
		setValidCustomer(state, payload) {
			state.validCustomer = payload;
		}
	},
	getters: {
		getterUser				: state => state.user,
		getterChangePassword	: state => state.change_password,
		getterLogin				: state => state.isLogin,
		getterLead				: state => state.lead,
		getterCustomer			: state => state.customer,
		getterCustomerLanding	: state => state.customerLanding,
		getterCustomerLeadAgent	: state => state.customerLeadAgent,
		getterProfileImage		: state => state.profile_image,
		getterLevel				: state => state.level,
		getterSatement      	: state => state.statement,
		getterCustomerAgent     : state => state.customerAgent,
		getterCustomerAddresses	: state => state.customerAddresses,
		getterValidCustomer		: state => state.validCustomer
	},
	actions: {
		// =================================================================
		//  CUSTOMER ACTIONS
		// =================================================================
		async getCustomer({commit}, payload) {
			
			await this.$hefesto.$post('/customers/find', { id_email: payload.id_email }).then( (axios_response) => {
				
				const { data } = axios_response;
				if( payload.origin == "landing" ){
					
					commit('setCustomerLanding', data);
				}
				else{
					
					commit('setCustomer', data);
					commit('setCustomerAddresses', data?.addresses || [] );
				}
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async getCustomerAgent({ commit }, payload){
			
			await this.$hefesto.$get(`/app-agent/user/get-by-customer`).then( (axios_response) => {
				
				const { data } = axios_response;
				commit('setCustomerAgent', data.body);
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			})
		},
		async validCustomer({commit}, payload) {
			
			await this.$hefesto.$post('/customers/valid-email', payload).then( (axios_response) => {
				
				commit('setValidCustomer', axios_response);
			}).catch( (axios_error) => {
				
				commit('setValidCustomer', axios_error.response.data);
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async updateCustomer({commit}, payload) {
			
			await this.$hefesto.$put('/customers/update?type=user', payload ).then( (axios_response) => {
				
				const { data } = axios_response;
				let data_user = {
					id				: data.db_user._id,
					name			: `${ data.db_user.first_name } ${ data.db_user.lest_name }`,
					email			: data.db_user.email,
					profile_image	: `${ data.db_customer.profile_image }?v=${ randomNumber() }`
				};
				commit('setCustomer', data.db_customer);
				commit('setUser', data_user);
				this.$cookies.set('user-store'	, JSON.stringify( data_user ), { path: '/', maxAge: 60 * 60 * 24 * 7 });
				commit('setNotification', { content: { type: 'request' }, type: 'success', data: axios_response });
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async getLevelCustomer({ commit }) {
			
			await this.$hefesto.$get(`/app-agent/level/level-customer`).then( (axios_response) => {
				
				const { data } = axios_response;
				commit('setProfileImage', data.result.profile_image ? `${ data.result.profile_image }?v=${ randomNumber() }` : null);
				commit('setLevel', data.result);
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async updateUser({ commit }, payload) {
			
			commit('setUser', payload);
		},
		// =================================================================
		//  LEAD ACTIONS
		// =================================================================
		async createLead({ commit }, payload) {
			
			await this.$hefesto.$post(`/app-agent/lead/create`, payload).then( (axios_response) => {
				
				const { data } = axios_response;
				commit('setLead', { valid: true, type: "create", data: data });
			}).catch( (axios_error) => {
				
				commit('setLead', { valid: false, type: "create", data: null });
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			})
		},
		async updateLead({ commit }, payload) {
			
			await this.$hefesto.$put(`/app-agent/lead/update`, payload).then( (axios_response) => {
				
				const { data } = axios_response;
				commit('setLead', { valid: true, type: "update", data: data });
			}).catch( (axios_error) => {
				
				commit('setLead', { valid: false, type: "update", data: null });
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			})
		},
		async saveCallLead({ commit }, payload) {
			
			let data_lead = {
				google_add_id	: this.$cookies.get('gclid') 		? this.$cookies.get('gclid').value 	: null,
				origin_add		: this.$cookies.get('origin_add') 	? this.$cookies.get('origin_add') 	: null,
				utms			: {
					utmSource	: this.$cookies.get('utmSource') 	? this.$cookies.get('utmSource')	: null,
					utmMedium	: this.$cookies.get('utmMedium') 	? this.$cookies.get('utmMedium') 	: null,
					utmCampaign	: this.$cookies.get('utmCampaign') 	? this.$cookies.get('utmCampaign') 	: null,
					utmTerm		: this.$cookies.get('utmTerm') 		? this.$cookies.get('utmTerm') 		: null
				},
				client_ip		: null
			};
			await this.$axios.$get('https://api.ipify.org/?format=json').then( async (axios_response) => {

				data_lead.client_ip = axios_response.ip;
				await this.$hefesto.$post( '/users/call-lead/create', data_lead ).then( (axios_response) => {}).catch( (axios_response) => {});
			}).catch( async (axios_error) => {

				await this.$hefesto.$post( '/users/call-lead/create', data_lead ).then( (axios_response) => {}).catch( (axios_response) => {});
			});
		},
		async registerCallLead({ commit }, payload){

		},
		// =================================================================
		//  STATEMENT ACTIONS
		// =================================================================
		async getStatementByCustomer({ commit }, payload){
			
			let exist_id = payload ? `?customer=${ payload.id }`: '';
			await this.$hefesto.$get(`/app-billing/statement/get-by-customer${ exist_id }`).then( (axios_response) => {
				
				const { data } = axios_response;
				commit('setStatement', data);
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			})
		},
		// =================================================================
		//  USER ACTIONS
		// =================================================================
		updateLoginUser({ commit }, payload) {
			
			if( ( payload.token_login || payload.custom_checkout == true ) && payload.data_user ){
				
				commit('setUser', payload.data_user );
				commit('setLogin', true);
				commit('setProfileImage', `${ payload.data_user.profile_image }?v=${ randomNumber() }` );
			}
			else if( ['/account/dashboard'].find( (item) => payload.current_route.indexOf(item) >= 0 ) ){
				
				this.$router.push('/account/login');
			}
		},
		async loginUser({commit, state}, payload) {
			
			await this.$hefesto.$post(`/users/login`, payload).then( (axios_response) => { 
				
				const { data } 	= axios_response;
				let data_user 	= {
					id				: data.user.id,
					name			: data.user.name,
					email			: data.user.email,
					profile_image	: `${ data.user.profile_image }?v=${ randomNumber() }`
				};
				commit('setProfileImage', `${ data.user.profile_image }?v=${ randomNumber() }` );
				
				this.$cookies.set('token-app'	, data.user.token, { path: '/', maxAge: 60 * 60 * 24 * 7 });
				this.$cookies.set('user-store'	, JSON.stringify( data_user ), { path: '/', maxAge: 60 * 60 * 24 * 7 });
				
				this.$cookies.remove("gclid");
				this.$cookies.remove("origin_add"); 
				this.$cookies.remove("utmSource"); 
				this.$cookies.remove("utmMedium");
				this.$cookies.remove("utmCampaign");
				this.$cookies.remove("utmTerm");
				
				commit('setUser', data_user);
				commit('products/setCart', { products: data.cart.products, details: data.cart.details_cart }, { root: true });
				commit('products/setCartSaveLater', { products: data.cart.save_later, details: data.cart.details_cart_save_later }, { root: true });
				commit('produts/setCoupon', data.cart.coupon, { root: true });
				commit('setLogin', true);
				
				commit('setNotification', { content: { type: 'request' }, type: 'success', data: { title: `Hello ${ data.user.name }`, message: "Welcome to Cata1og" } });
				
			}).catch((axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async logoutUser({commit, state}, payload) {
			
			this.$cookies.remove('token-app');
			this.$cookies.remove('user-store');
			
			commit('setUser', null);
			commit('setCart', { products: [], details: null });
			commit('setCartSaveLater', { products: [], details: null });
			commit('setCoupon', { name: null });
			
			commit('setLogin', false);
		},
		async updateCustomerPassword({commit}, payload) {
			
			await this.$hefesto.$put(`/users/update-password`,payload).then( (axios_response) => {
				
				const { title, message } = axios_response;
				
				commit('setNotification', { content: { type: 'request' }, type: 'success', data: { title: title, message: message } });
				
				commit('setChangePassword', { data: { message: "Successfully Updated Password" }})    
			}).catch((axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async changeProfileImage({ commit }, payload){
			
			let result = validUploadFile( payload.file, payload.input_name, payload.max_size, payload.old_file_path, payload.extra_data );
			
			if( result.success ){
				
				await this.$hefesto.$post('/customers/add-profile-image', result.data ).then( (axios_response) => {
					
					const { data } = axios_response;
					commit('setProfileImage', `${ process.env.VUE_APP_HEFESTO_API_URL_FILE }/${ data.url }?v=${ randomNumber() }`);
				}).catch( ( axios_error ) => {
					
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
				});
			}
			else{
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: result.title, message: result.message, app_version: this.$cookies.get('app-version') } });
			}
		},
		async findCustomerLeadAgent({ commit }, payload) {
			
			await this.$hefesto.$post('/users/find-agent', { email: payload.email } ).then( (axios_response) => {
				
				const { data } = axios_response;
				commit('setCustomerLeadAgent', data);
			}).catch( ( axios_error ) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: axios_error.response.data.title, message: axios_error.response.data.message } });
			});
		},
		// =================================================================
		//  ADDRESS ACTIONS
		// =================================================================
		async saveCustomerAddress({commit}, payload) {
			
			await this.$hefesto.$put('/customers/save-address', payload ).then( (axios_response) => {
				
				const { data } = axios_response;
				commit('setCustomerAddresses', data?.addresses || []);
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async deleteCustomerAddress({commit}, payload) {
			
			await this.$hefesto.$put('/customers/delete-address', payload ).then( (axios_response) => {
				
				const { data } = axios_response;
				commit('setCustomerAddresses', data?.addresses || []);
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
	}
}
